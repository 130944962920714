<template>
  <div id="apply-history">
    <header>
      <img
          class="notice-icon"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice.png"
          alt=""
      />
      <div class="notice-content">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice-content.png"
            alt=""
        />
      </div>
    </header>
    <div class="cleaning-container">
      <div class="cleaning-gif">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/apply-history/cleaning-gif.jpg"
            alt=""
            ref="cleaning-gif"
        />
      </div>
      <div
          class="cleaning-button"
          @click="doClean"
      >
        一键删除网贷记录
      </div>
    </div>
    <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
    >

      <div class="apply-history-container">
        <div
            class="apply-history-item"
            v-for="product in applyHistoryList"
            :key="product.productId"
        >
          <div class="apply-history-item-header">
            <img
                :src="product.logo"
                alt=""
            />
            <div class="product-name">{{ product.productName }}</div>
            <div class="label">{{ product.label }}</div>
          </div>
          <div class="apply-history-item-loan-money">
            <span>{{ (product.moneySection && product.moneySection.split("-")[1]) || 80000 }}</span>
            <em>最高可借(元)</em>
          </div>
          <div class="apply-history-item-loan-time">
            最长期限{{
              (product.loanTime && product.loanTime[product.loanTime.length - 1]) || 12
            }}个月，年利率低至{{ product.dayRate || '6.0' }}%起
          </div>
          <div
              class="apply-button"
              @click="toApply(product)"
          >
            已申请
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import {
  get_apply_history_list,
  apply_history_list_do_clean
} from '_network/applyHistory'
import { click_product_item } from '_network/superLoan'

export default {
  data() {
    return {
      timer: null,
      cleaningCount: 0,
      // 申请记录列表
      applyHistoryList: [],
      // 分页参数
      paramsList: {
        pageIdx: 1,
        pageSize: 10
      },
      // 分页加载是否已经结束
      finished: false,
      loading: false
    }
  },
  methods: {
    async doClean() {
      // 先判断当前的产品列表是否为空，为空则不清除
      if (this.applyHistoryList.length === 0) {
        this.$toast.fail('暂无申请记录')
        return
      }

      // 先初始化计数，并清除定时器
      clearInterval(this.timer)
      this.cleaningCount = 0

      // 通过倒计时对动画进行处理，总耗时应该为2.4s
      this.timer = setInterval(() => {
        this.cleaningCount++
        this.moveCleaningGif()
        if (this.cleaningCount === 47) {
          clearInterval(this.timer)
        }
      }, 50)

      try {
        // 发送清除列表的请求
        await apply_history_list_do_clean()

        // 初始化记录列表
        this.applyHistoryList = []

        // 清除以后重新获取列表
        await this.getApplyHistoryList()
      } catch (e) {}
    },
    // 移动精灵图的动画函数
    moveCleaningGif() {
      const x = this.cleaningCount % 8 * 4.507
      const y = Math.floor(this.cleaningCount / 8) * 4.507
      this.$refs['cleaning-gif'].style.transform = `translate(-${x}rem, -${y}rem)`
    },
    // 获取申请记录列表
    async getApplyHistoryList() {
      try {
        this.loading = true
        const { data = [] } = await get_apply_history_list(this.paramsList)
        this.loading = false
        // 如果获取的列表产品少于10个，则让列表结束触底
        if (data.length !== 10) {
          this.finished = true
        }
        this.applyHistoryList.push(...data)
      } catch (e) {}
    },
    /**
     * @description 列表产品的按钮状态
     * @param productType 产品类型 1h5 2联登 3大额贷 4信贷api-h5 5信贷api-本地
     * @param bigLoanApplyState 大额贷产品申请结果 0失败 1成功
     * @return 1立即申请 2已申请
     * */
    getProductButtonState({ productType, bigLoanApplyState }) {
      if (
          // 产品类型为h5
          productType === 1
          // 产品类型为大额贷 并且 申请成功
          || (productType === 3  && bigLoanApplyState === 1)
      ) {
        return 1
      } else {
        return 0
      }
    },
    // 申请产品
    async toApply(product) {
      if (this.getProductButtonState(product) !== 1) {
        // 这里说明产品不是h5类型或者不是申请成功的大额贷，直接终止即可
        return
      }

      if (product.productType === 1) {
        // 需要先添加1次产品的点击次数
        await click_product_item(product.productId)
        // h5产品直接跳转链接
        location.href = product.url
      } else {
        // 其他均为申请成功的大额贷，将产品存于bigLoanProduct字段然后跳转，由于大额贷页面的名称字段为name，所以这里重新赋值了产品名
        product.name = product.productName
        localStorage.setItem('bigLoanProduct', JSON.stringify(product))
        await this.$router.push('/examining')
      }
    },
    // 分页列表触底
    onLoad() {
      this.paramsList.pageIdx++
      this.getApplyHistoryList()
    }
  },
  created() {
    this.getApplyHistoryList()
    document.title = '申请记录'
  }
}
</script>

<style lang="less" scoped>
@keyframes move {
  0% {
    transform: translateX(8.4324rem);
  }

  100% {
    transform: translateX(-11.1622rem);
  }
}

#apply-history {
  min-height: 100vh;
  padding-bottom: 0.533rem;
  background: #F8F8F8;

  header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.8649rem;
    padding-left: 17px;
    background: #FBF6E8;

    .notice-icon {
      width: 0.3784rem;
      margin-right: 0.1351rem;
    }

    .notice-content {
      overflow: hidden;
      width: 8.4324rem;
      height: 0.3784rem;

      img {
        width: 11.1622rem;
        transform: translateX(8.4324rem);
        animation: move 16s linear infinite;
      }
    }
  }

  .cleaning-container {
    overflow: hidden;
    height: 9.6rem;
    background: linear-gradient( 180deg, #3865EF 0%, #0043F5 100%);

    .cleaning-gif {
      overflow: hidden;
      width: 4.507rem;
      height: 4.507rem;
      margin: 1.413rem auto 1.867rem;

      img {
        width: 36.053rem;
      }
    }

    .cleaning-button {
      width: 4.987rem;
      height: 1.333rem;
      margin: 0 auto;
      background: #25C385;
      border-radius: 0.667rem;
      font-weight: 500;
      font-size: 0.427rem;
      color: #FFFFFF;
      text-align: center;
      line-height: 1.333rem;
    }
  }

  .apply-history-container {
    padding: 0 0.427rem;

    .apply-history-item {
      position: relative;
      margin-top: 0.267rem;
      padding: 0.373rem;
      background: #FFF;
      border-radius: 0.213rem;

      .apply-history-item-header {
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        color: #2C75F9;

        img {
          width: 0.533rem;
          border-radius: 0.107rem;
        }

        .product-name {
          margin: 0 0.213rem 0 0.16rem;
          font-weight: 500;
          font-size: 0.4rem;
          color: #333333;
        }

        .label {
          padding: 0.08rem;
          background: #ECF3FF;
          border-radius: 0.053rem;
        }
      }

      .apply-history-item-loan-money {
        display: flex;
        align-items: center;
        margin-top: 0.373rem;
        font-weight: 600;
        font-size: 0.693rem;
        color: #FF4B3E;

        em {
          margin-left: 0.16rem;
          font-weight: 400;
          font-size: 0.32rem;
          color: #999999;
        }
      }

      .apply-history-item-loan-time {
        font-weight: 400;
        font-size: 0.32rem;
        color: #999999;
      }

      .apply-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0.613rem;
        top: 50%;
        width: 1.867rem;
        height: 0.853rem;
        background: #D1D1D1;
        border-radius: 0.427rem;
        transform: translateY(-50%);
        color: #000000;
      }
    }
  }
}
</style>