import request from '_utils/request'

const get_apply_history_list = (params) => {
  return request({
    url: '/api/product/getH5ProductApplyList',
    method: 'GET',
    params
  })
}

const apply_history_list_do_clean = () => {
  return request({
    url: '/api/product/delProductApplyList',
    method: 'POST'
  })
}

export {
  get_apply_history_list,
  apply_history_list_do_clean
}
